







































import "@trevoreyre/autocomplete-vue/dist/style.css";

import { Component, Vue } from "vue-property-decorator";

import Autocomplete from "@trevoreyre/autocomplete-vue";
import { readGeneralFuse } from "@/store/main/getters";

@Component({
  components: {
    Autocomplete,
  },
})
export default class OmniSearchBox extends Vue {
  get generalFuse() {
    return readGeneralFuse(this.$store);
  }

  public colorRefs = {
    Creator: "success",
    Manager: "primary",
    Account: "danger",
  };

  public search(query) {
    if (query) {
      // clean up query
      query = query.toLowerCase().trim();
      query = query
        .replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .replace("youtube.com/", "");
      const results = this.generalFuse.search(query);
      // return the top 10 results and omit non-true values
      return results
        .map((item) => item.item)
        .filter((item) => item)
        .slice(0, 10);
    }
    return [];
  }

  public goToItem(event) {
    const type = event.type.toLowerCase().replace(" ", "-") + "s";
    let url: string;
    if (type == "accounts") {
      url = `/mastersheet/${event.id}`;
    } else {
      url = `/manage/${type}/${event.id}`;
    }
    this.$refs["omni-autocomplete"]["value"] = undefined;
    let link = document.createElement("a");
    link.target = "_blank";
    link.href = url;
    link.click();
  }
}































import { Component, Vue } from "vue-property-decorator";

import NavigationMenu from "@/components/NavigationMenu.vue";
import OmniSearchBox from "@/components/OmniSearchBox.vue";
import UserInfo from "@/components/UserInfo.vue";
import { capitalize } from "@vue/shared";
@Component({
  components: {
    NavigationMenu,
    OmniSearchBox,
    UserInfo,
  },
})
export default class TopBar extends Vue {
  get breadcrumbs() {
    const path = this.$route.path;
    return path
      .split("/")
      .map((item) => {
        let name = capitalize(item.replace("/", "").replace("-", " "));
        if (name.toLowerCase() == "youtube") name = "YouTube";
        return {
          name,
          path: item,
        };
      })
      .filter((item) => item.name);
  }

  async followBreadcrumbs(index) {
    await this.$router.push(
      "/" +
        this.breadcrumbs
          .slice(0, index + 1)
          .map((item) => item.path)
          .join("/")
    );
  }
}




















































import "tippy.js/themes/light.css";

import { Component, Vue } from "vue-property-decorator";
import { readMaxScope, readMe } from "@/store/auth/getters";

import { logOut } from "@/helpers/auth";
import tippy from "tippy.js";

@Component
export default class UserInfo extends Vue {
  async logout() {
    await logOut();
    await this.$router.push("/login");
  }

  get me() {
    return readMe(this.$store);
  }

  get maxScope() {
    return readMaxScope(this.$store);
  }

  get currentUser() {
    return {
      name: [this.me.first_name, this.me.last_name].join(" ") || "Unregistered",
      email: this.me.email,
      role: this.maxScope,
    };
  }

  mounted() {
    // @ts-ignore
    tippy("#user-info-trigger", {
      theme: "light",
      placement: "bottom-start",
      interactive: true,
      trigger: "click",
      content: this.$refs.userInfoContent,
    });
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation"},[_c('div',{staticClass:"sidebar-header p-d-flex p-ai-center p-jc-between",attrs:{"id":"v-step-0"},on:{"click":_vm.toggleNavigation}},[_c('div',{staticClass:"p-d-flex p-ai-center p-jc-start"},[_c('LogoIcon',{staticStyle:{"margin-bottom":"2px"}}),_c('div',{staticClass:"logo-button-text"},[_vm._v("OMNI")])],1),_c('i',{staticClass:"fas fa-bars"})]),_c('Sidebar',{staticClass:"navigation-menu",attrs:{"baseZIndex":90000,"visible":_vm.navigationVisible,"position":"left","dismissable":"","showCloseIcon":false,"modal":""},on:{"update:visible":function($event){_vm.navigationVisible=$event}}},[_c('div',{staticClass:"sidebar-header-open p-d-flex p-ai-center p-jc-start",on:{"click":_vm.toggleNavigation}},[_c('LogoIcon',{staticStyle:{"margin-bottom":"2px"}}),_c('div',{staticClass:"logo-button-text"},[_vm._v("OMNI")])],1),_vm._l((_vm.navItems),function(item){return _c('div',{key:item.title,staticClass:"menu-item-wrapper p-d-flex p-flex-column"},[_c('router-link',{staticClass:"p-d-flex p-ai-center p-jc-start",attrs:{"to":item.href,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(item.disabled)?_c('div',{class:isActive
              ? 'navigation-item-active navigation-item'
              : 'navigation-item',staticStyle:{"cursor":"default"}},[_c('Icon',{staticClass:"navigation-icon",attrs:{"icon":item.iconify,"color":"white","width":"20","height":"20","filled":isActive}}),_c('div',[_vm._v(_vm._s(item.title))])],1):(!item.external)?_c('div',{class:isActive
              ? 'navigation-item-active navigation-item'
              : 'navigation-item',on:{"click":navigate}},[_c('Icon',{staticClass:"navigation-icon",attrs:{"icon":item.iconify,"color":"white","width":"20","height":"20","filled":isActive}}),_c('div',[_vm._v(_vm._s(item.title))])],1):_c('a',{staticClass:"navigation-item",attrs:{"href":item.href,"target":"_blank"}},[_c('Icon',{staticClass:"navigation-icon",attrs:{"icon":item.iconify,"color":"white","width":"20","height":"20","filled":isActive}}),_c('div',[_vm._v(_vm._s(item.title))])],1)]}}],null,true)}),_c('div',{staticClass:"menu-item-children-wrapper"},_vm._l((item.children),function(child){return _c('router-link',{key:child.title,staticClass:"p-d-flex p-ai-center p-jc-start",attrs:{"to":child.href,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
return [_c('div',{class:isActive
                ? 'navigation-item-active navigation-item'
                : 'navigation-item',on:{"click":navigate}},[_c('Icon',{staticClass:"navigation-icon-child",attrs:{"icon":child.iconify,"color":"white","width":"20","height":"20","filled":isActive}}),_c('div',[_vm._v(_vm._s(child.title))])],1)]}}],null,true)})}),1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
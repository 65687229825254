




























































































































import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import { Component, Vue } from "vue-property-decorator";
import { addIcon } from "@iconify/vue2";

import LogoIcon from "@/components/icons/LogoIcon.vue";
import MilestoneIcon from "@/assets/custom-icons/milestones";
import { dispatchSetNavigationVisible } from "@/store/main/actions";
import { readNavigationVisible } from "@/store/main/getters";

@Component({
  components: {
    LogoIcon,
  },
})
export default class NavigationMenu extends Vue {
  get navigationVisible() {
    return readNavigationVisible(this.$store);
  }

  set navigationVisible(value: boolean) {
    dispatchSetNavigationVisible(this.$store, value);
  }

  public toggleNavigation() {
    dispatchSetNavigationVisible(this.$store, !this.navigationVisible);
  }

  public beforeMount() {
    addIcon("custom:milestones", {
      body: MilestoneIcon,
      width: 20,
      height: 20,
    });
  }

  public navItems = [
    {
      title: "Home",
      iconify: "dashicons:admin-home",
      filled: true,
      href: "/overview",
      external: false,
    },
    {
      title: "Manage",
      iconify: "fluent:people-list-24-regular",
      href: "/manage",
      external: false,
    },
    {
      title: "Mastersheet",
      iconify: "bx:bx-spreadsheet",
      href: "/mastersheet",
      disabled: true,
      external: false,
      children: [
        {
          title: "YouTube Channel",
          iconify: "feather:youtube",
          href: "/mastersheet/youtube",
        },
        {
          title: "Clip Licensing",
          iconify: "clarity:video-gallery-line",
          href: "/mastersheet/clip-licensing",
        },
        {
          title: "Podcast",
          iconify: "mdi:google-podcast",
          href: "/mastersheet/podcast",
        },
        {
          title: "China Platform",
          iconify: "mdi:television-classic",
          href: "/mastersheet/china-platform",
        },
      ],
    },
    {
      title: "Analytics",
      iconify: "ion:analytics-outline",
      href: "/analytics/accounts",
      external: false,
    },
    {
      title: "Milestones",
      iconify: "custom:milestones",
      href: "https://milestones.collabasia.co/",
      external: true,
    },
    {
      title: "Help",
      iconify: "fluent:chat-help-24-regular",
      href: "/help",
      external: false,
    },
  ];
}
